import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Sponsor = () => {
	const data = useStaticQuery(graphql`
		query {
			content: file(relativePath: {eq:"sponsor.md"}) {
				childMarkdownRemark {
					html
					frontmatter {
						id
						title
					}
				}
			}
		}
	`)

	return (
		<section id="/about" className="p-4 md:p-12">
			<div className="markdown-content" dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }} />
		</section>
	)
}

export default Sponsor
