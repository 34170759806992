import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import Sponsor from '../components/Page/Sponsor'
import ContactForm from '../components/Form/ContactForm'

const sponsorPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<Sponsor />
				</div>
				<div className="md:w-1/2 mx-auto">
					<ContactForm
						enquiryType={`sponsor`}
						namePlaceholderText={`F.A.B. Sponsor`}
						emailPlaceholderText={`sponsor@thefestival.org`}
						messagePlaceholderText={`Let's talk about sponsoring the Festival!`}
						submitButtonText={`Send Sponsorship enquiry`}
					/>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-005.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default sponsorPage
