import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { validateEmail, validateEmpty } from '../../utils/validation'
import {
	FORM_LABEL_STYLES,
	FORM_LABEL_INVALID_STYLES,
	FORM_INPUT_STYLES,
	FORM_INPUT_INVALID_STYLES,
	FORM_VALIDATION_MESSAGE_STYLES, FORM_BUTTON_STYLES, FORM_BUTTON_INVALID_STYLES
} from '../../constants/form'

const ContactForm = props => {

	const enquiryTypes = {
		general: { label: 'General Enquiry', email: 'hello@boswellbookfestival.com' },
		friend: { label: 'Become a Friend', email: 'friends@boswellbookfestival.co.uk' },
		// list: { label: 'Join our Mailing List', email: 'enews@boswellbookfestival.co.uk' }, // handled by mailchimp form
		volunteer: { label: 'Become a Volunteer', email: 'volunteering@boswellbookfestival.co.uk' },
		sponsor: { label: 'Sponsorship Opportunities', email: 'sponsoring@boswellbookfestival.com' }
	}

	let enquiryKeys = Object.keys(enquiryTypes)

	if (props.enquiryType && props.enquiryType.length > 0) {
		enquiryKeys = [ props.enquiryType ]
	}

	const initialFormName = `boswell-book-festival-contact-${enquiryKeys[0]}`
	const initialEnquiry = enquiryKeys[0]

	const [formName, setFormName] = useState(initialFormName)
	const [enquiry, setEnquiry] = useState(initialEnquiry)
	const [name, setName] = useState('')
	const [nameIsValid, setNameIsValid] = useState(true)
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(true)
	const [message, setMessage] = useState('')
	const [messageIsValid, setMessageIsValid] = useState(true)
	const [captcha, setCaptcha] = useState('')
	const [captchaIsValid, setCaptchaIsValid] = useState(true)

	const [formIsValid, setFormIsValid] = useState(false)
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)

	const submitUrl = '/'

	const handleEnquiryChange = event => {
		const selectedOption = event.target[event.target.selectedIndex]
		setEnquiry(selectedOption.value)
		setFormName(`boswell-book-festival-contact-${selectedOption.value}`)
	}

	const handleNameInputChange = event => {
		setName(event.target.value)
		setNameIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleEmailInputChange = event => {
		setEmail(event.target.value)
		setEmailIsValid(validateEmail(event.target.value))
		handleFormValidation()
	}

	const handleMessageInputChange = event => {
		setMessage(event.target.value)
		setMessageIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleCaptchaInputChange = event => {
		setCaptcha(event.target.value)
		setCaptchaIsValid(!validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleFormValidation = () => {
		setFormIsValid(
			validateEmpty(name) &&
				validateEmail(email) &&
				validateEmpty(message) &&
				!validateEmpty(captcha)
		)
	}

	const handleSubmit = event => {
		event.target.setAttribute('disabled', true)
		submitForm().then(
			event.target.removeAttribute('disabled')
		)
	}

	const encodeForm = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const submitForm = async (event) => {
		// submits netlify form via fetch
		setShowError(false)
		setShowSuccess(false)

		const data = {
			enquiry: enquiryTypes[enquiry].label,
			name: name,
			email: email,
			message: message,
			mailto: enquiryTypes[enquiry].email,
		}

		await fetch(submitUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encodeForm({'form-name': formName, ...data}),
		})
			.then(() => navigate('/contact-thank-you'))
			.catch(error => {
				setShowError(true)
			})
	}

	return (
		<div className="min-h-screen-90">
			<form
				action="/contact-thank-you"
				method="POST"
				name={formName}
				className="mx-auto p-2 pt-12 mb-24 max-w-xl"
				data-netlify={true}
				data-netlify-honeypot="captcha"
			>
				<input type="hidden" name="form-name" value={formName} />
				<input type="hidden" name="mailto" value={enquiryTypes[enquiry].email} />

				{/*<p className={`mb-12 text-xl`}>The Boswell Book Festival office will reopen in September.</p>*/}

				<label className={`flex flex-col items-start mb-8`}>
					<p className={FORM_LABEL_STYLES}>What can we help with?</p>
					<select
						name="enquiry"
						value={enquiry}
						className={FORM_INPUT_STYLES}
						onChange={handleEnquiryChange}
						onBlur={() => {}}
						required
					>
						{enquiryKeys.map((key, index) => {
							const option = enquiryTypes[key]
							return <option key={index} value={key}>{option.label}</option>
						})}
					</select>
				</label>

				<label className={`flex flex-col items-start`}>
					<p className={`${FORM_LABEL_STYLES} ${!nameIsValid && FORM_LABEL_INVALID_STYLES}`}>Your name</p>
					<input
						type="text"
						name="name"
						value={name}
						placeholder={props.namePlaceholderText || 'Avid Book-Reader'}
						className={`${FORM_INPUT_STYLES} ${!nameIsValid && FORM_INPUT_INVALID_STYLES}`}
						onChange={handleNameInputChange}
						onKeyUp={handleNameInputChange}
						required
					/>
				</label>
				<p className={`${FORM_VALIDATION_MESSAGE_STYLES} ${!nameIsValid && 'opacity-100'}`}>
					Please enter a contact name
				</p>

				<label className={`flex flex-col items-start`}>
					<p className={`${FORM_LABEL_STYLES} ${!emailIsValid && FORM_LABEL_INVALID_STYLES}`}>Your email address</p>
					<input
						type="email"
						name="email"
						value={email}
						placeholder={props.emailPlaceholderText || 'ilovereading@books.com'}
						className={`${FORM_INPUT_STYLES} ${!emailIsValid && FORM_INPUT_INVALID_STYLES}`}
						onChange={handleEmailInputChange}
						onKeyUp={handleEmailInputChange}
						required
					/>
				</label>
				<p className={`${FORM_VALIDATION_MESSAGE_STYLES} ${!emailIsValid && 'opacity-100'}`}>
					Please enter a valid email address
				</p>

				<label className={`flex flex-col items-start`}>
					<p className={`${FORM_LABEL_STYLES} ${!messageIsValid && FORM_LABEL_INVALID_STYLES}`}>Message</p>
					<textarea
						name="message"
						value={message}
						placeholder={
							props.messagePlaceholderText ||
							"How can I get involved with the Boswell Book Festival?"
						}
						className={`h-64 ${FORM_INPUT_STYLES} ${!messageIsValid && FORM_INPUT_INVALID_STYLES}`}
						onChange={handleMessageInputChange}
						onKeyUp={handleMessageInputChange}
						required
					/>
				</label>
				<p className={`${FORM_VALIDATION_MESSAGE_STYLES} ${!messageIsValid && 'opacity-100'}`}>
					Please enter a message
				</p>

				<label className={`flex flex-col items-start hidden`}>
					<p className={`${FORM_LABEL_STYLES} ${!captchaIsValid && FORM_LABEL_INVALID_STYLES}`}>Captcha</p>
					<input
						type="text"
						name="captcha"
						value={captcha}
						placeholder={
							props.captchaPlaceholderText ||
							"if you can see this, don't fill it in!"
						}
						className={`${FORM_INPUT_STYLES} ${!captchaIsValid && FORM_INPUT_INVALID_STYLES}`}
						onChange={handleCaptchaInputChange}
						onKeyUp={handleCaptchaInputChange}
					/>
				</label>
				<p className={`${FORM_VALIDATION_MESSAGE_STYLES} ${!captchaIsValid && 'opacity-100'} hidden`}>
					Please do NOT fill this in
				</p>

				{showError && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-red font-semibold">
						There has been a problem submitting your message. Please try again.
					</p>
				)}

				{showSuccess && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-mid-green-alt font-semibold">
						Thank you! A member of the Boswell Book Festival team will be in touch soon.
					</p>
				)}

				<button
					className={`${FORM_BUTTON_STYLES} ${!formIsValid && FORM_BUTTON_INVALID_STYLES}`}
					type="button"
					disabled={!formIsValid}
					onClick={handleSubmit}
				>
					{props.submitButtonText ? props.submitButtonText : 'Send Message'}
				</button>
			</form>
		</div>
	)
}

export default ContactForm
